import { observer } from "mobx-react-lite";
import { useContext, useEffect, useState } from "react";
import { storeContext } from "../../../store/root-store";
import { DateButtons } from "../../../enums/DateButtons";

export const DatePanel = observer(() => {
  const { eventsStore, messagesStoreGl, settingsGl } = useContext(storeContext);

  const [currentButton, setCurrentButton]: [string, Function] = useState(
    settingsGl.currentDayFilter,
  );

  const onSetCurrentButton = (value: DateButtons) => {
    eventsStore.listOfPreviewEvents = [];
    settingsGl.currentPage = 0;
    settingsGl.currentDayFilter = value;
    setCurrentButton(value);
  };

  const dateFilter = async (start: number, finish: number) => {
    settingsGl.dateFromSec = start;
    settingsGl.dateToSec = finish;
    eventsStore.listOfPreviewEventsLoaded = false;
  };

  const onTodayButtonClick = () => {
    onSetCurrentButton(DateButtons.today);
  };
  const onTomorrowButtonClick = () => {
    onSetCurrentButton(DateButtons.tomorrow);
  };
  const onWeekButtonClick = () => {
    onSetCurrentButton(DateButtons.week);
  };

  const onAllButtonClick = () => {
    onSetCurrentButton(DateButtons.all);
  };

  return (
    <div className="mt-2">
      <div className="d-flex">
        <div
          onClick={onTodayButtonClick}
          className={
            "my-time-panel-button me-1  " +
            (currentButton == DateButtons.today
              ? " my-time-panel-button-active"
              : "")
          }
        >
          Сегодня
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.tomorrow
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onTomorrowButtonClick}
        >
          Завтра
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.week
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onWeekButtonClick}
        >
          Неделя
        </div>
        <div
          className={
            "my-time-panel-button me-1" +
            (currentButton == DateButtons.all
              ? " my-time-panel-button-active"
              : "")
          }
          onClick={onAllButtonClick}
        >
          Все
        </div>
      </div>
      <div></div>
    </div>
  );
});
