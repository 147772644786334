import { BasePage } from "../../common/BasePage";
import { useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { IFullEvent } from "../../../interfaces/IFullEvent";
import { storeContext } from "../../../store/root-store";
import { observer } from "mobx-react-lite";
import { QueryService } from "../../../services/QueryService";
import { StringHelper } from "../../../helpers/StringHelper";
import sanitizeHtml from "sanitize-html";
import ImageGallery from "react-image-gallery";
import { Loader } from "../../common/Loader";

const images = [
  {
    original:
      "https://your-city-events.zahar-dev.ru/tgMedia/10_20_2024/meropriyatiya_krd/no_group/a1011e57eca66a027a4aec320fb65353.jpg",
    thumbnail:
      "https://your-city-events.zahar-dev.ru/tgMedia/10_20_2024/meropriyatiya_krd/no_group/a1011e57eca66a027a4aec320fb65353.jpg",
  },
  {
    original: "https://picsum.photos/id/1015/1000/600/",
    thumbnail: "https://picsum.photos/id/1015/250/150/",
  },
  {
    original: "https://picsum.photos/id/1019/1000/600/",
    thumbnail: "https://picsum.photos/id/1019/250/150/",
  },
];

export const SingleEventPage = observer(() => {
  const { eventId } = useParams();
  const { singleStoreGL } = useContext(storeContext);
  const [isLoading, setIsLoading] = useState(true);

  const [fullEvent] = useState({} as IFullEvent);
  const loadEventInfo = async () => {
    if (eventId) {
      setIsLoading(true);
      const fullEvent = await singleStoreGL.loadSingleEvent(+eventId);
      singleStoreGL.fullEvent.description = StringHelper.addTgLabel(
        singleStoreGL.fullEvent.body,
      );
      setIsLoading(false);
      console.log(singleStoreGL.fullEvent.description);
    }
  };
  useEffect(() => {
    loadEventInfo();
  }, []);
  return (
    <BasePage>
      {!isLoading && (
        <div
          className="container"
          style={{ overflowY: "scroll", height: 100 + "%" }}
        >
          <div style={{ width: 100 + "%", maxHeight: 100 + "%" }}>
            <ImageGallery
              items={singleStoreGL.getImgForGallery(
                singleStoreGL.fullEvent.img_path,
              )}
              showNav={false}
              showThumbnails={
                singleStoreGL.getImgForGallery(singleStoreGL.fullEvent.img_path)
                  .length > 1
              }
              useBrowserFullscreen={false}
              showPlayButton={false}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <p
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(singleStoreGL.fullEvent.body, {
                    allowedTags: ["b", "i", "em", "strong", "a"],
                    allowedAttributes: {
                      a: ["href"],
                    },
                  }),
                }}
                style={{ whiteSpace: "pre-line" }}
              ></p>
            </div>
          </div>
        </div>
      )}
      {isLoading && <Loader />}
    </BasePage>
  );
});
