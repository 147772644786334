import { makeAutoObservable } from "mobx";
import { DateButtons } from "../enums/DateButtons";

export class Settings {
  public dateFromSec: number = 0;
  public dateToSec: number = 0;
  public currentPage: number = 0;
  public chunkLength: number = 4;
  public hasMore: boolean = true;
  public currentDayFilter: DateButtons = DateButtons.today;
  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Получить временное интервал(от и до)
   * @param dayFilter
   */
  public static getTimeInterval(dayFilter: DateButtons): {
    startSec: number;
    finishSec: number;
  } {
    switch (dayFilter) {
      case DateButtons.today:
        const todaySec = Math.floor(Date.now() / 1000);
        return {
          startSec: todaySec,
          finishSec: todaySec,
        };
      case DateButtons.tomorrow:
        const tomorrowSec = Math.floor(Date.now() / 1000) + 24 * 60 * 60;
        return {
          startSec: tomorrowSec,
          finishSec: tomorrowSec,
        };
      case DateButtons.week:
        const getEndOfTheWeek = (dateObj: Date) => {
          let day = dateObj.getDay();
          let diff = 7 - day;
          let neDate = new Date();
          return Math.floor(
            new Date(neDate.setDate(dateObj.getDate() + diff)).getTime() / 1000,
          );
        };

        const date = new Date();
        const startDiff =
          date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
        const endDiff =
          date.getDate() + date.getDay() + (date.getDay() === 0 ? -6 : 1);

        // Set the date to the start of the week by setting it to the calculated difference
        const startOfTheWeek = new Date(date.setDate(startDiff));
        const endOfTheWeek = new Date(date.setDate(endDiff));
        // endOfTheWeek.toUTCString()
        const startOfTheWeekSec = Math.floor(startOfTheWeek.getTime() / 1000);
        const endOfTheWeekSec = getEndOfTheWeek(date);

        return {
          startSec: startOfTheWeekSec,
          finishSec: endOfTheWeekSec,
        };

      case DateButtons.all:
        return {
          startSec: 0,
          finishSec: 0,
        };
    }
  }
}
export const settingsGl = new Settings();
