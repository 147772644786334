import React from "react";
import { QueryService } from "../../../services/QueryService";
import { EventPreview } from "../../../types/EventPreview";

interface IEvent {
  key?: any;
  event: EventPreview;
  className: string;
}
const getImgPath = (imgPaths: string) => {
  const paths = imgPaths.split(";");
  return paths[0];
};
export const EventComp = ({ event, className }: IEvent) => {
  return (
    <div className={className}>
      <div className="card">
        <div style={{ width: 100 + "%", height: 25 + "vh" }}>
          {event.img_path && (
            <img
              width={100 + "%"}
              height={100 + "%"}
              src={
                QueryService.getServerUri() + "/" + getImgPath(event.img_path)
              }
              className="card-img-top"
              alt="..."
            />
          )}
          {!event.img_path && (
            <img
              width={100 + "%"}
              height={100 + "%"}
              src={"/no-img.svg"}
              className="card-img-top"
              alt="..."
            />
          )}
        </div>
        <div className="card-body">
          <p
            className=""
            style={{
              height: 25 + "vh",
              overflow: "hidden",
              wordBreak: "break-all",
            }}
          >
            {event.body}
          </p>
        </div>
      </div>
    </div>
  );
};
